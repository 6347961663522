.live-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100% !important;
}

.my-container {
  max-width: 100% !important;
  /* margin: 0; */
  /* overflow: hidden; */
  /* padding-right: 0px; */
}

.small-font {
  font-size: small;
}

.button-space {
  width: -webkit-fill-available;
}

.table-label {
  height: max-content;
  line-height: 3 !important;
  /* padding-top: 5px; */
  /* padding-bottom: 30px; */
}

.width-lh-col {
  width: 7% !important;
}

.width-center-col {
  width: 77% !important;
}

.width-rh-col {
  width: 16% !important;
}

.success-color {
  background-color: #41bc6a !important;
}

.warning-color {
  background-color: #f3b32a !important;
}

.danger-color {
  background-color: #b11c32 !important;
}

.data-value {
  background-color: var(--bs-gray-200) !important;
  border-color: var(--bs-gray-400) !important;
  color: var(--bs-gray-600) !important;
  line-height: 2 !important;
}

.data-label {
  line-height: 2 !important;
}

.progress-bar-vertical {
  min-height: 100%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  align-items: flex-end;
  -webkit-align-items: flex-end; /* Safari 7.0+ */
  padding: 0px !important;
}

.progress-bar {
  width: 100%;
  border-radius: 10px;
}

.chart-top-battery-line-height {
  line-height: 1 !important;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  transform: rotate(90deg);
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 100vw; /* Slider handle height */
  background: #04aa6d; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04aa6d; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
