.live-view-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.live-view-table {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.live-view-table th span {
  justify-content: center;
}
