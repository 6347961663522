.field-log-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.field-log-table {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.field-log-table th span {
  justify-content: center;
}
