.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: var(--child-container-height);
}

.not-found-image {
  height: 500px;
}

.not-found-heading {
  color: #333333;
  font-weight: normal;
}

.not-found-para {
  color: #334155;
}
