.main-home-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.home-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}
.home-heading {
  color: #000000;
}
