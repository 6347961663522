@media all and (min-width: 480px) {
  .login {
    width: 100%;
    padding-top: 60px;
  }

  .login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
