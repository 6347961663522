.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: calc(var(--child-container-height) - var(--nav-container-height));
}

.about-image {
  height: 100px;
}
